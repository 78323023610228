<app-shared-header
  title="Encuestas"
  [showTitle]="true"
  [badgeNumber]="polls.length"
></app-shared-header>

<div class="published-polls-container">
  <div fxLayout="column">
    <mat-form-field>
      <mat-label>
        <mat-icon class="icon-label">search</mat-icon>
        &nbsp;&nbsp;Buscar encuesta
      </mat-label>

      <input
        type="text"
        placeholder="Busca por nombre o descripción"
        matInput
        [(ngModel)]="search"
        autocomplete="off"
      />
    </mat-form-field>
  </div>

  <div class="content-polls">
    <cdk-virtual-scroll-viewport itemSize="50" class="example-viewport">
      <div *cdkVirtualFor="let poll of polls" class="example-item">
        <mat-card
          class="card-clients"
          *ngIf="
            !search ||
            poll.name.toLowerCase().includes(search.toLowerCase()) ||
            poll.description?.toLowerCase().includes(search.toLowerCase())
          "
        >
          <div fxLayout="row">
            <div fxFlex="10">
              <div
                fxLayout="column"
                fxLayoutAlign="center center"
                class="full-height"
              >
                <mat-icon class="client-icon">ballot</mat-icon>
              </div>
            </div>

            <div fxFlex="70">
              <div fxFlex="column" style="padding-left: 5px">
                <div>{{ poll.name }}<br /></div>
                <div>
                  <span class="text-small-bold"
                    >Expira: {{ poll.expire_date | date : 'dd MMM yyyy' }}</span
                  >
                </div>
              </div>
            </div>

            <div fxFlex="20" fxLayoutAlign="center center" class="actions-btns">
              <div fxLayout="column" fxLayoutAlign="center center">
                <div
                  *ngIf="false"
                  fxFlex="20"
                  fxLayoutAlign="center center"
                  class="actions-btns"
                ></div>

                <div fxFlex="35" fxLayoutAlign="end end" class="actions-btns">
                  <div
                    mat-mini-fab
                    aria-label="Seleccionar"
                    class="btn-action ml-4"
                    (click)="checkOffline(poll)"
                  >
                    <mat-icon class="action-icon">navigate_next</mat-icon>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-card>
      </div>
    </cdk-virtual-scroll-viewport>
  </div>
</div>

<ngx-spinner
  name="published-polls-spinner"
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="rgb(92, 184, 92)"
  type="ball-spin-fade"
>
  <p style="font-size: 16px; color: rgb(255, 255, 255); text-align: center">
    Cargando encuestas publicadas...
  </p>
</ngx-spinner>
