<app-header
  [isOfflineMode]="offlineMode.status && offlineMode.from === 'clients'"
  [title]="pollTitle"
  [img]="this.sectionImg"
  [actual_section]="actualSection"
  [total_section]="totalSection"
>
</app-header
><br /><br />
<app-header-user></app-header-user>

<app-bread-crumb
  [sectionCompare]="actualSection"
  [sectionTotal]="this.totalSection"
>
</app-bread-crumb>
<br />
<div fxLayoutAlign="center center">
  {{ sectionTitle }}
</div>
<div *ngIf="this.question" fxLayout="column" class="action-response">
  <div fxLayout="column">
    <div fxLayoutAlign="center center" class="question-title">
      <label>{{ this.question.title }}</label>
      <ng-container *ngIf="question.required"
        ><span class="required"></span
      ></ng-container>

      <span
        class="material-icons help-icon"
        (click)="
          offlineMode.status && offlineMode.from === 'clients'
            ? getHelp64(question)
            : getHelp(question.help, question.helpfile)
        "
        *ngIf="
          this.question.help.length > 1 || this.question.helpfile.length > 1
        "
      >
        help_outline
      </span>
    </div>
    <div
      fxLayoutAlign="center center"
      class="sub-title"
      *ngIf="this.question.subtitle"
    >
      {{ this.question.subtitle }}
    </div>
  </div>
  <br />
  <!-- question.type === 1 || 2-->
  <ng-container *ngIf="question.type_id === 1">
    <app-selection
      [question]="this.question"
      [idResponse]="this.idResponse"
      [selected]="selected"
      (optionSelected)="getSelected($event)"
      (showHideSpiner)="showHideSpiner($event)"
    >
    </app-selection>
  </ng-container>
  <ng-container *ngIf="question.type_id === 2">
    <app-multiple
      [question]="this.question"
      [idResponse]="this.idResponse"
      [selected]="selected"
      (optionSelected)="getSelected($event)"
      (showHideSpiner)="showHideSpiner($event)"
    >
    </app-multiple>
  </ng-container>

  <ng-container *ngIf="question.type_id === 3">
    <app-text
      [question]="this.question"
      [idResponse]="this.idResponse"
      (optionSelected)="getSelected($event)"
      (showHideSpiner)="showHideSpiner($event)"
    ></app-text>
  </ng-container>

  <ng-container *ngIf="question.type_id === 4">
    <!-- <app-loadfile
      [question]="this.question"
      [idResponse]="this.idResponse"
      (optionSelected)="getSelected($event)"
    ></app-loadfile> -->

    <!-- <app-multiple-files
      [idResponse]="this.idResponse"
      (optionSelected)="getSelected($event)"
      [question]="question"
    ></app-multiple-files> -->

    <app-files-webcam
      [idResponse]="this.idResponse"
      (optionSelected)="getSelected($event)"
      [question]="question"
    ></app-files-webcam>
  </ng-container>
  <ng-container *ngIf="question.type_id === 5">
    <app-scale
      [question]="this.question"
      [idResponse]="this.idResponse"
      (optionSelected)="getSelected($event)"
      (showHideSpiner)="showHideSpiner($event)"
    ></app-scale>
  </ng-container>

  <!-- <ng-container *ngIf="question.type_id===8">
    <app-ranking
    [question]="this.question"
    [idResponse]="this.idResponse"
    [selected]="selected"
    (optionSelected)="getSelected($event)">

    </app-ranking>
  </ng-container> -->

  <ng-container *ngIf="question.type_id === 8">
    <app-rankingmv
      [question]="this.question"
      [idResponse]="this.idResponse"
      [selected]="selected"
      (optionSelected)="getSelected($event)"
      (showHideSpiner)="showHideSpiner($event)"
    >
      >
    </app-rankingmv>
  </ng-container>

  <ng-container
    *ngIf="question.type_id === 9 && this.question.collect_time !== true"
  >
    <app-date
      [title]="this.question.questionoptions[0].title"
      [question]="this.question"
      [idResponse]="this.idResponse"
      [selected]="selected"
      (optionSelected)="getSelected($event)"
      (showHideSpiner)="showHideSpiner($event)"
    ></app-date>
  </ng-container>

  <ng-container
    *ngIf="question.type_id === 9 && this.question.collect_time === true"
  >
    <app-datetime
      [title]="this.question.questionoptions[0].title"
      [question]="this.question"
      [idResponse]="this.idResponse"
      [selected]="selected"
      (optionSelected)="getSelected($event)"
      (showHideSpiner)="showHideSpiner($event)"
    ></app-datetime>
  </ng-container>

  <ng-container *ngIf="question.type_id === 10">
    <app-number
      [question]="this.question"
      [idResponse]="this.idResponse"
      [section]="this.section"
      (optionSelected)="getSelected($event)"
      (showHideSpiner)="showHideSpiner($event)"
      (setMessageValidate)="setMessageValidate($event)"
      (moveToNextSection)="moveToNextSection()"
    >
    </app-number>
  </ng-container>

  <ng-container *ngIf="question.type_id === 12">
    <app-pricedropdown
      [question]="this.question"
      [idResponse]="this.idResponse"
      [selected]="selected"
      (updateQuestionRequired)="updateQuestionRequired($event)"
      (optionSelected)="getSelected($event)"
      (showHideSpiner)="showHideSpiner($event)"
    >
    </app-pricedropdown>
  </ng-container>
  <ng-container *ngIf="question.type_id === 13">
    <app-cooler
      [idResponse]="idResponse"
      [question]="this.question"
      (updateQuestionRequired)="updateQuestionRequired($event)"
      (answerBody)="setAnswerCoolerBody($event)"
      (showHideSpiner)="showHideSpiner($event)"
    ></app-cooler>
  </ng-container>
</div>

<div fxLayout="row" class="btn-footer">
  <div fxFlex="50" fxLayoutAlign="start center">
    <button class="back-button" mat-button (click)="backClicked()">
      Volver
    </button>
  </div>

  <div *ngIf="this.question" fxFlex="50" fxLayoutAlign="end center">
    <button
      class="next-button"
      [ngClass]="{
        'next-button-active': questionRequired,
        'next-button-disabled': !questionRequired
      }"
      mat-button
      [disabled]="questionRequired"
      (click)="save()"
    >
      Siguiente
    </button>
  </div>
</div>

<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="rgb(92, 184, 92)"
  type="ball-spin-fade"
>
  <p style="font-size: 20px; color: rgb(92, 184, 92)">Cargando...</p>
</ngx-spinner>
