<app-shared-header [showBackButton]="true" [backRoute]="'/tasks'"></app-shared-header>

<div class="assigned-container">
  <div class="assigned-title-box">
    <div class="assigned-title">Tareas Asignadas a mi</div>
  </div>

  <div class="assigned-tasks-box">
    <mat-tab-group (selectedTabChange)="onTabChanged($event)">
      <mat-tab label="Pendientes" aria-label="Pendientes">
        <ng-template mat-tab-label>
          <span matBadge="{{ pending }}" matBadgeOverlap="false">Pendientes</span>
        </ng-template>

        <mat-form-field class="full-medium-width">
          <mat-label>
            <mat-icon class="icon-label">search</mat-icon>
            &nbsp;&nbsp;Buscar Cliente
          </mat-label>

          <input
            #searchCustomerInput
            type="text"
            placeholder="Busca por nombre o descripción"
            matInput
            autocomplete="off"
          />
        </mat-form-field>

        <div class="pending-tasks-list">
          <app-assigned-tasks-list status="['Creada']"></app-assigned-tasks-list>
        </div>
      </mat-tab>

      <mat-tab label="Completadas" aria-label="Completadas">
        <ng-template mat-tab-label>
          <span matBadge="{{ completed }}" matBadgeOverlap="false">Completadas</span>
        </ng-template>

        <mat-form-field class="full-medium-width">
          <mat-label>
            <mat-icon class="icon-label">search</mat-icon>
            &nbsp;&nbsp;Buscar Cliente
          </mat-label>

          <input
            #searchCustomerInput
            type="text"
            placeholder="Busca por nombre o descripción"
            matInput
            autocomplete="off"
          />
        </mat-form-field>

        <div class="pending-tasks-list">
          <app-assigned-tasks-list status="['Confirmada', 'Completada, Rechazada']"></app-assigned-tasks-list>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
