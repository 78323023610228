<div *ngIf="showHeader" class="header-container">
  <div *ngIf="showBackButton" class="header-back-button" (click)="onGoBack()">
    <mat-icon>arrow_back</mat-icon>
    <p class="header-back-button-text">Volver</p>
  </div>

  <div *ngIf="showTitle" class="header-content">
    <h1 class="header-title">
      {{ title }}
      <span class="header-badge" *ngIf="showBadge">{{ badgeNumber }}</span>
    </h1>
  </div>
</div>
