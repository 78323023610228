import { NavigationEnd, Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { OfflineService } from 'src/app/polls/services/offline.service';
import { SynchronizationService } from 'src/app/synchronization/services/synchronization.service';
import { dev } from 'src/environments/environment';
import { MatTabChangeEvent } from '@angular/material/tabs';
import Swal from 'sweetalert2';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { Network } from '@ngx-pwa/offline';
import { AuthService } from '../../../../auth/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SpinnerService } from 'src/app/polls/services/spinner.service';

@Component({
  selector: 'app-shared-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
})
export class NavbarComponent implements OnInit, OnDestroy {
  syncPollsCount$: Observable<number> = of(0);
  syncPollsCountSubcription$: Subscription;
  public selectedTabIndex: number;
  public activeInEnv =
    dev.env === 'local' || dev.env === 'develop' || dev.env === 'qa';

  public isOffline: boolean = true;
  private boundHandleStorageChange: (this: Window, ev: StorageEvent) => any;
  private readonly updateCount: Subject<number> = new Subject<number>();
  online$ = this.network.onlineChanges;
  showTab: boolean = false;
  private showTabSubscription: Subscription;

  progressText: string = 'Descargando Clientes';
  progressValue: number = 0;
  simpleSpinner: boolean = false;

  getUpdateCount() {
    return this.updateCount.asObservable();
  }

  constructor(
    private router: Router,
    private offlineService: OfflineService,
    private synchronizationService: SynchronizationService,
    protected network: Network,
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    private spinnerService: SpinnerService
  ) {
    this.syncPollsCount$ = this.synchronizationService.getUpdateCount();

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.updateActiveTabBasedOnRoute(event.urlAfterRedirects);
      }
    });
    this.boundHandleStorageChange = this.handleStorageChange.bind(this);
  }

  ngOnInit(): void {
    this.spinnerService.spinnerState$.subscribe(
      (state: {
        simple: boolean;
        show: boolean;
        message?: string;
        progress?: { progressText: string; progressValue: number };
      }) => {
        if (state.show) {
          if (state.simple) {
            this.simpleSpinner = state.simple;
            this.progressText = state.message;
          }
          this.spinner.show('synchronizing-navbar');
          if (state.progress && !state.simple) {
            this.progressText = state.progress.progressText;
            this.progressValue = state.progress.progressValue;
          }
        } else {
          this.spinner.hide('synchronizing-navbar');
          this.progressText = '';
          this.progressValue = 0;
        }
      }
    );

    this.showTabSubscription =
      this.authService.$showTabByEnterpriseSubject.subscribe((key: boolean) => {
        if (key === true) {
          this.verifyEnterprisesToShowContent();
        }
      });
    window.addEventListener('storage', this.boundHandleStorageChange);
    this.checkOffline();
    this.initializeSyncPollsCount();

    this.syncPollsCountSubcription$ = this.syncPollsCount$.subscribe(
      (count) => {
        if (count !== 0) {
          this.offlineService.startMonitoringStatusSync();
        } else {
          this.syncPollsCount$ = of(0);
        }
      }
    );
    this.isOffline = !(localStorage.getItem('statusConnection') === 'true');

    if (this.router.url === '/home') {
      this.router.navigate(['/poll']);
    }

    this.updateActiveTabBasedOnRoute(this.router.url);
  }

  ngOnDestroy(): void {
    if (this.showTabSubscription) {
      this.showTabSubscription.unsubscribe();
    }
    window.removeEventListener('storage', this.boundHandleStorageChange);
    this.syncPollsCountSubcription$.unsubscribe();
  }

  verifyEnterprisesToShowContent() {
    const enterprises = JSON.parse(localStorage.getItem('enterprises') || '[]');
    const idsToCheck = [99, 98, 92];
    if (
      idsToCheck?.every(
        (id) => !enterprises.some((enterprise: any) => enterprise.id === id)
      )
    ) {
      this.showTab = true;
    } else {
      this.showTab = false;
    }
  }

  private async initializeSyncPollsCount() {
    const syncPolls = await this.offlineService.getSyncPolls();
    const count = syncPolls.filter(
      (sp) =>
        sp.status === 'unsynchronized-edit' ||
        sp.status === 'unsynchronized' ||
        sp.status === 'sync-responses-completed' ||
        sp.status === 'sync-close-response' ||
        sp.status === 'sync-completed'
    ).length;

    this.synchronizationService.nextUpdateCount(count);
  }

  handleStorageChange(event: StorageEvent): void {
    if (event.key === 'isOfflineMode') {
      this.checkOffline();
    }
  }

  checkOffline() {
    this.isOffline = !(localStorage.getItem('statusConnection') === 'true');
  }

  onChangeTab(index: number): void {
    this.isOffline = !(localStorage.getItem('statusConnection') === 'true');

    this.selectedTabIndex = index;

    if (this.isOffline && index === 4) {
      this.onOfflineDeactived('Tareas');
      this.selectedTabIndex = 1;
      return;
    }

    const routes = [
      '/home',
      '/poll',
      '/synchronization',
      '/published',
      '/tasks',
    ];
    if (routes[index]) {
      this.router.navigate([routes[index]]);
    }
  }

  updateActiveTabBasedOnRoute(url: string): void {
    const tabMap: { [key: string]: number } = {
      '/home': 0,
      '/poll': 1,
      '/synchronization': 2,
      '/published': 3,
      '/tasks': 4,
    };

    const matchingTab = Object.entries(tabMap).find(([route]) =>
      url.startsWith(route)
    );
    if (matchingTab) {
      const [, tabIndex] = matchingTab;
      this.selectedTabIndex = tabIndex;
    }
  }

  onOfflineDeactived(tabName: string) {
    let message = '';
    if (!(localStorage.getItem('statusConnection') === 'true')) {
      message = `Para acceder a "${tabName}", debes tener conexión a internet.`;
    }
    Swal.fire({
      title: 'Sin conexión a internet',
      html: message,
      icon: 'warning',
      showCancelButton: true,
      showConfirmButton: false,
      cancelButtonColor: '#CA0000',
      cancelButtonText: 'Cerrar',
    }).then(async () => {
      if (!(localStorage.getItem('statusConnection') === 'true')) {
        this.onChangeTab(1);
        return;
      }
    });
  }
}
