<mat-drawer-container
  [ngClass]="
    firstTime || isReconfiguration
      ? 'global-bg-gradient mat-drawer-container-config'
      : 'global-bg-gradient container-drawer'
  "
>
  <mat-drawer #sidenavSelectRoute class="mat-drawer-container-config">
    <app-select-route
      (closeNavSelectRouteEvent)="closeNav($event)"
      (setListOptionsVendedor)="setListOptionsVendedor($event)"
      (progressChange)="handleProgressChange($event)"
      (progressTextChange)="progressTextChange($event)"
      (progressSpinnerChange)="progressSpinnerChange($event)"
      [reconfiguration]="isReconfiguration"
    ></app-select-route>
  </mat-drawer>
  <mat-drawer
    #sidenavFiltersMenuInClients
    class="content-mat-drawer"
    mode="over"
    position="end"
    style="position: fixed"
  >
    <app-filters-menu-in-clients
      (closeNavEvent)="closeSidenavFiltersMenuInClients()"
      (refreshClients)="filterClients($event)"
    ></app-filters-menu-in-clients>
  </mat-drawer>

  <mat-drawer-content style="overflow: hidden">
    <div fxLayout="column" class="content-poll">
      <div fxLayoutAlign="center">
        <h1 class="header-title" matBadgeOverlap="false" (click)="resetDB()">
          Clientes<span>{{ countClients }}</span>
        </h1>
      </div>
      <div class="content-polls">
        <app-client-all
          (openNavSelectRouteEvent)="sidenavSelectRoute.open()"
          (closeNavSelectRouteEvent)="closeNav($event)"
          (runPollSync)="runPollSync($event)"
          (openNavSelectFilterEvent)="sidenavFiltersMenuInClients.open()"
          (countClients)="refreshcountClients($event)"
          [offlineActive]="offlineActive"
        ></app-client-all>
      </div>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
<ngx-spinner
  zIndex="70"
  name="synchronizing"
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="rgb(92, 184, 92)"
  type="ball-clip-rotate-pulse"
>
  <div class="progress-upload">
    <div style="margin-top: 40px; margin-bottom: 12px">Sincronizando</div>
    <mat-progress-bar
      mode="determinate"
      [value]="progressValue"
      class="sync-progress-bar"
    ></mat-progress-bar>
    <div style="margin-top: 16px">
      {{ progressText }}
      <span>{{ progressValue }}%</span>
    </div>
  </div>
</ngx-spinner>
