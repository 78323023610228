<div fxLayout="column" class="content-poll">
  <mat-sidenav-container class="global-bg-gradient">
    <mat-sidenav #sidenav="matSidenav" class="mat-elevation-z7">
      <app-filters-menu
        (optionSelected)="closeNav()"
        (refreshStats)="fetchClientsFilter($event)"
        (closeNavEvent)="closeNav()"
      ></app-filters-menu>
    </mat-sidenav>
    <mat-sidenav-content style="overflow: hidden">
      <app-header
        [isOfflineMode]="isOfflineMode"
        [title]="poll.name"
        [sub_title]="'Seleccione'"
      >
      </app-header
      ><br />
      <app-header-user></app-header-user>
      <mat-tab-group
        style="height: 100%"
        mat-stretch-tabs
        (selectedTabChange)="changeTab($event)"
      >
        <mat-tab label="Pendientes" fxLayoutAlign="center">
          <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">assignment_late</mat-icon>

            Pendientes
          </ng-template>

          <div class="c">Total: {{ pendingClientscount }}</div>
          <div fxLayout="column">
            <div fxLayout="row">
              <div [fxFlex]="isOfflineMode ? 75 : 90">
                <mat-form-field class="full-medium-width">
                  <mat-label>
                    <mat-icon class="icon-label">search</mat-icon>
                    &nbsp;&nbsp;Buscar cliente
                  </mat-label>
                  <input
                    type="text"
                    #text_pend
                    placeholder="Busca por nombre, códigos de cliente, dirección o vendedor"
                    matInput
                    [(ngModel)]="searchPend"
                    (ngModelChange)="onSearchCustomerChange($event)"
                    autocomplete="off"
                  />
                </mat-form-field>
              </div>
              <div
                *ngIf="isOfflineMode"
                fxFlex="15"
                fxLayoutAlign="center center"
              >
                <button
                  mat-mini-fab
                  color="accent"
                  class="btn-search"
                  (click)="searchClient()"
                >
                  <mat-icon class="icon-search">search</mat-icon>
                </button>
              </div>
              <div fxFlex="10">
                <button
                  mat-mini-fab
                  color="primary"
                  class="btn-search"
                  (click)="sidenav.toggle(); searchPend = ''"
                >
                  <div *ngIf="isFilters" class="badge-filter">
                    {{ numberFilters }}
                  </div>
                  <mat-icon class="icon-search"> filter_alt</mat-icon>
                </button>
              </div>
            </div>
          </div>
          <br />
          <section
            class="content-clients"
            infiniteScroll
            (scrolled)="onScrollDownPend()"
            [scrollWindow]="scrollDisabled"
            [infiniteScrollDistance]="distance"
          >
            <div *ngIf="!loading_pending_clientes">
              <div
                fxLayout="column"
                *ngFor="let client of this.pending_clients; let i = index"
              >
                <mat-card class="card-clients">
                  <div fxLayout="row">
                    <div fxFlex="10">
                      <mat-icon class="client-icon"
                        >perm_contact_calendar</mat-icon
                      >
                    </div>
                    <div fxFlex="55">
                      <div fxFlex="column">
                        <div>{{ client.descr_cliente }}</div>
                        <div>{{ client.direccion }}</div>
                        <div>
                          <span class="datePoll"
                            >Código: {{ client.cliente_id }}</span
                          >
                        </div>
                      </div>
                    </div>
                    <div fxFlex="35" fxLayoutAlign="center center">
                      <button
                        mat-stroked-button
                        class="btn-poll"
                        (click)="verifyInitPoll(poll, client)"
                      >
                        Seleccionar
                      </button>
                    </div>
                  </div>
                </mat-card>
              </div>
            </div>
            <div fxLayout="column" fxLayoutAlign="center center">
              <mat-spinner
                *ngIf="loading_pending_clientes"
                diameter="50"
              ></mat-spinner>

              <br />
              <span *ngIf="isOfflineMode && loading_pending_clientes"
                >Esta búsqueda puede tardar un poco</span
              >
              <span *ngIf="isOfflineMode && pending_clients.length === 0"
                >No hay clientes encontrados</span
              >
            </div>
          </section>

          <br />
        </mat-tab>

        <mat-tab label="Completados" fxLayoutAlign="center" *ngIf="!preview">
          <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">assignment_turned_in</mat-icon>
            Completados
          </ng-template>
          <div class="c">Total: {{ completedClientscount }}</div>
          <div fxLayout="column">
            <div fxLayout="row">
              <div [fxFlex]="isOfflineMode ? 85 : 100">
                <mat-form-field class="full-medium-width">
                  <mat-label>
                    <mat-icon class="icon-label">search</mat-icon>
                    &nbsp;&nbsp;Buscar cliente
                  </mat-label>
                  <input
                    type="text"
                    #text_comp
                    placeholder="Busca por nombre, códigos de cliente, dirección o vendedor"
                    matInput
                    [(ngModel)]="searchComp"
                    (ngModelChange)="onSearchCustomerChange($event)"
                    autocomplete="off"
                  />
                </mat-form-field>
              </div>
              <div
                *ngIf="isOfflineMode"
                fxFlex="15"
                fxLayoutAlign="center center"
              >
                <button
                  mat-mini-fab
                  color="accent"
                  class="btn-search"
                  (click)="searchClient()"
                >
                  <mat-icon class="icon-search">search</mat-icon>
                </button>
              </div>
              <div *ngIf="!isOfflineMode" fxFlex="10" class="button-filter">
                <button
                  mat-mini-fab
                  color="primary"
                  class="btn-filter"
                  (click)="sidenav.toggle()"
                >
                  <mat-icon> filter_list</mat-icon>
                </button>
              </div>
            </div>
          </div>

          <section
            class="content-clients"
            infiniteScroll
            (scrolled)="onScrollDownComp()"
          >
            <div *ngIf="!loading_completed_clientes">
              <div
                fxLayout="column"
                *ngFor="let client of this.completed_clients; let i = index"
              >
                <mat-card class="card-clients">
                  <div fxLayout="row">
                    <div fxFlex="10">
                      <mat-icon class="client-icon"
                        >perm_contact_calendar</mat-icon
                      >
                    </div>
                    <div fxFlex="60">
                      <div fxFlex="column">
                        <div>{{ client.descr_cliente }}</div>
                        <div>{{ client.direccion }}</div>
                        <div>
                          <span class="datePoll"
                            >Código: {{ client.cliente_id }}</span
                          >
                        </div>
                      </div>
                    </div>
                    <div
                      fxFlex="30"
                      fxLayoutAlign="center center"
                      fxLayout="row"
                      class="inColumn"
                    >
                      <!-- <button mat-stroked-button class="btn-poll">Completada</button> -->
                      <button
                        *ngIf="!isOfflineMode"
                        mat-flat-button
                        class="btn-option"
                        aria-label="Reiniciar encuesta"
                        (click)="
                          restartPoll(
                            client.descr_cliente,
                            client.id,
                            idPoll,
                            poll.filter_by_user
                          )
                        "
                      >
                        <mat-icon matTooltip="Reiniciar">cached</mat-icon>
                      </button>

                      <button
                        *ngIf="!isOfflineMode"
                        mat-flat-button
                        class="btn-option"
                        aria-label="Editar encuesta"
                        (click)="editPoll(idPoll, client)"
                      >
                        <mat-icon matTooltip="Editar">create</mat-icon>
                      </button>

                      <!--  <button
                      *ngIf="client.local"
                      mat-mini-fab
                      class="btn-upload active blink"
                      aria-label="Sincronizar encuesta"
                      (click)="uploadPoll(idPoll, client)"
                    >
                      <mat-icon class="upload-icon" matTooltip="Sincronizar Encuesta">sync</mat-icon>
                    </button>

                    <button
                      *ngIf="client.problem"
                      mat-mini-fab
                      class="btn-upload btn-warning active blink"
                      aria-label="Sincronizar encuesta"
                      (click)="uploadPoll(idPoll, client)"
                    >
                      <mat-icon class="upload-icon" matTooltip="Sincronizar Encuesta">sync_problem</mat-icon>
                    </button> -->
                    </div>
                  </div>
                </mat-card>
              </div>
            </div>
            <div fxLayout="column" fxLayoutAlign="center center">
              <mat-spinner
                *ngIf="loading_completed_clientes"
                diameter="50"
              ></mat-spinner>
              <span
                *ngIf="
                  !loading_completed_clientes &&
                  isOfflineMode &&
                  completed_clients.length === 0
                "
                >No hay clientes encontrados</span
              >
            </div>
          </section>
          <br />
        </mat-tab>
      </mat-tab-group>

      <div fxLayout="column">
        <div fxLayoutAlign="center center" class="div-footer">
          <button
            mat-raised-button
            class="btn-next fixed-button"
            (click)="returnDashboard()"
          >
            Volver
          </button>
        </div>
      </div>

      <div fxLayout="column">
        <div fxLayoutAlign="center center">
          <button
            class="scrollToTopBtn"
            *ngIf="showButtonTop"
            (click)="onScrollTop()"
          >
            Subir
          </button>
        </div>
      </div>

      <ngx-spinner
        name="loadingClients"
        bdColor="rgba(51,51,51,0.8)"
        size="medium"
        color="rgb(92, 184, 92)"
        type="ball-spin-fade"
      >
        <p style="font-size: 20px; color: rgb(92, 184, 92)">Cargando...</p>
      </ngx-spinner>

      <ngx-spinner
        name="uploading"
        bdColor="rgba(51,51,51,0.8)"
        size="medium"
        color="rgb(92, 184, 92)"
        type="ball-clip-rotate-pulse"
        class="content-spinner"
      >
        <div class="progress-upload">
          <mat-progress-bar
            mode="determinate"
            [value]="progressUploadResValue"
            class="sync-progress-bar"
          ></mat-progress-bar>
          <div style="margin-top: 16px">
            {{ progressUploadResText }}
            <span>{{ progressUploadResValue }}%</span>
          </div>
        </div>
      </ngx-spinner>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
