<ng-container *ngIf="question.format === 'button'">
  <div
    fxLayoutAlign="center center"
    fxLayout="column"
    *ngFor="let option of this.question.questionoptions; let i = index"
  >
    <mat-card
      mat-ripple
      class="card-question {{ this.selected === i ? 'card-selected' : '' }}"
      (click)="onSelected(i, option.logicJump)"
    >
      <div fxLayout="column" fxLayoutAlign="center center">
        <div class="title">{{ option.title }}</div>
      </div>
    </mat-card>
  </div>
</ng-container>
<ng-container *ngIf="question.format === 'lista_desp'">
  <div fxLayoutAlign="center center" fxLayout="column">
    <mat-form-field class="input-full-width mat-focused mb-3" appearance="outline">
      <mat-label>Opciones</mat-label>
      <mat-select
        placeholder="Seleccione opción"
        name="optionSelect"
        [(value)]="this.question.questionoptions[selected]"
      >
        <mat-option
          *ngFor="let option of this.question.questionoptions; let i = index"
          [value]="option"
          (click)="onSelected(i, option.logicJump)"
          >{{ option.title }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </div>
</ng-container>
