<div class="flex">
  <div
    *ngIf="hasResponse === false"
    class="container-cards"
    [ngClass]="{ 'justify-content-center': urlLength === 1 }"
  >
    <mat-card
      *ngFor="let url of urls; let index = index"
      fxLayoutAlign="space-around center"
      fxLayout="column"
      class="p-m-2"
    >
      <div *ngIf="url.type === 'pdf'" class="pdf-div">
        <mat-icon>attachment</mat-icon>
        <p *ngIf="url.name">{{ url.name }}</p>
        <p *ngIf="!url.name">{{ url.fileName }}</p>
      </div>

      <img
        *ngIf="url.type !== 'pdf'"
        (click)="view(url.files)"
        mat-card-image
        [src]="url.files"
        height="200"
      />

      <div fxLayout="row" fxLayoutAlign="space-between center" class="w-150">
        <a
          (click)="onSelectFileReemp(index, url)"
          mat-flat-button
          color="accent"
        >
          <mat-icon>refresh</mat-icon>
        </a>
        <a
          (click)="removeSelectedFile(index, url)"
          mat-flat-button
          color="warn"
        >
          <mat-icon>delete_outline</mat-icon>
        </a>
      </div>
    </mat-card>
  </div>
  <div
    *ngIf="hasResponse === true && allData"
    class="container-cards"
    [ngClass]="{ 'justify-content-center': allData.length === 1 }"
  >
    <mat-card
      *ngFor="let url of allData || []; let index = index"
      fxLayoutAlign="space-around center"
      fxLayout="column"
      class="p-m-2"
    >
      <div *ngIf="url.type === 'pdf'" class="pdf-div">
        <mat-icon>attachment</mat-icon>
        <p *ngIf="url.name">{{ url.name }}</p>
        <p *ngIf="!url.name">{{ url.fileName }}</p>
      </div>

      <img
        *ngIf="url.type !== 'pdf'"
        (click)="view(url.files)"
        mat-card-image
        [src]="url.files"
        height="200"
      />

      <div fxLayout="row" fxLayoutAlign="space-between center" class="w-150">
        <a
          (click)="onSelectFileReemp(index, url)"
          mat-flat-button
          color="accent"
        >
          <mat-icon>refresh</mat-icon>
        </a>
        <a
          (click)="removeSelectedFile(index, url)"
          mat-flat-button
          color="warn"
        >
          <mat-icon>delete_outline</mat-icon>
        </a>
      </div>
    </mat-card>
  </div>
  <div fxLayout="column" fxLayoutAlign="center center" class="marginOnlyCam">
    <div class="sub-section-1">
      <label
        class="tag mat-button"
        for="upload"
        style="font-size: large; font-weight: bold"
        >{{ tagQuestion }}</label
      >
    </div>
    <div
      class="sub-section-2"
      fxLayout="row"
      fxLayoutGap="20px"
      fxLayoutAlign="center center"
    >
      <div
        fxFlex="{{ question.only_phone ? 0 : 45 }}"
        fxLayoutAlign="center center"
        *ngIf="question.only_phone === false"
      >
        <ng-container *ngIf="isDisabled === false">
          <label for="upload" class="no-tag">
            <mat-icon class="mat-icon-custom-cam">folder_open</mat-icon>
            <input
              type="file"
              (change)="onSelectFile($event)"
              multiple
              hidden
              id="upload"
              #upload
              *ngIf="question.only_phone === false"
              accept="image/*"
            />
          </label>
        </ng-container>
        <ng-container *ngIf="isDisabled === true">
          <mat-icon class="mat-icon-custom-cam">folder</mat-icon>
        </ng-container>
      </div>
      <div>
        <button
          mat-icon-button
          [disabled]="isDisabled"
          color="primary"
          (click)="takeWebCam(-1)"
          class="camara-icon"
        >
          <mat-icon
            class="mat-icon-custom-cam"
            [ngClass]="{ disable: isDisabled === true }"
            id="takeCam"
            style="cursor: pointer"
            >camera_enhance</mat-icon
          >
        </button>
      </div>
    </div>
    <div class="sub-section-1" *ngIf="isDisabled === true">
      <p style="color: orange">
        Se ha alcanzado la cantidad máxima de archivos permitidos
      </p>
    </div>
  </div>
</div>
