<div class="date-container">
  <div class="date-title-box">
    <p class="text-title">Fecha limite de cumplimiento</p>
  </div>

  <div class="date-box">
    <div class="date-help-box">
      <p class="text-help">Ingrese una fecha.</p>
    </div>

    <div class="date-input">
      <mat-form-field appearance="outline">
        <input
          matInput
          [matDatepicker]="picker"
          (dateInput)="handleAnswer($event)"
          (click)="picker.open()"
          [min]="minDate"
          autocomplete="off"
          readonly
          [value]="answerMaded || ''"
          placeholder="Ingresar Fecha"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
</div>
