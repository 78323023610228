import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as jwt_decode from 'jwt-decode';

import { ClientModel } from '../../models/client.model';
import { InfoCustomerPoll } from '../../models/info-customer-poll.model';
import { PollModel } from '../../models/poll.model';

@Component({
  selector: 'app-infoextra',
  templateUrl: './infoextra.component.html',
  styleUrls: ['./infoextra.component.css'],
})
export class InfoextraComponent implements OnInit {
  client: ClientModel;
  poll: PollModel;
  infoextrapoll: InfoCustomerPoll[] = [];
  infoextracustomer: InfoCustomerPoll[] = [];
  typeaccess: string;
  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.client = JSON.parse(localStorage.getItem('client'));
    this.poll = JSON.parse(localStorage.getItem('poll'));

    const decodedToken = jwt_decode(localStorage.getItem('token'));
    this.typeaccess = decodedToken.user.typeaccess;

    JSON.parse(this.poll?.infoextrapoll).forEach((element) => {
      if (element.selected) {
        this.infoextrapoll.push(element);
      }
    });

    JSON.parse(this.poll?.infoextracustomer).forEach((element) => {
      if (element.selected) {
        if (this.poll.uen === 99) {
          // if(element.field==="Comuna"){
          //   element.title='Tipo ramo'
          // }
          // if(element.title==='Segmento'){
          //   element.title='Ramo'
          // }

          // if(element.title==='Jefe de ventas'){
          //   element.title='Supervisor'
          // }

          switch (element.field) {
            case 'descr_segmento': {
              element.title = 'Ramo';
              break;
            }
            case 'descr_corta_comuna': {
              element.title = 'Tipo ramo';
              break;
            }
            case 'descr_jefe_ventas': {
              element.title = 'Supervisor';
              break;
            }
            case 'descr_subgerente': {
              element.title = 'Oficina comercial';
              break;
            }
            case 'descr_gerente': {
              element.title = 'Operación';
              break;
            }
            case 'descr_ofcom_venta': {
              element.title = 'Jefe de ventas';
              break;
            }
            case 'descr_distrito_venta': {
              element.title = 'Región';
              break;
            }
            case 'descr_zona_venta': {
              element.title = 'Gerencia';
              break;
            }
            default: {
              element.title = element.title;
              break;
            }
          }
          this.infoextracustomer.push(element);
        } else {
          this.infoextracustomer.push(element);
        }
      }
    });
  }
  initPoll() {
    this.router.navigate(['poll/client/init']);
  }
  returnPoll() {
    const source = localStorage.getItem('source');

    switch (source) {
      case 'list_client':
        localStorage.removeItem('poll');
        // this.router.navigate(['/poll', { tab: 2 }]);
        this.router.navigate(['/poll']);
        break;
      case 'poll':
        this.router.navigate(['/poll/client'], {
          queryParams: { id: this.poll.id, name: this.poll.name },
        });
        break;

      default:
        this.router.navigate(['/poll/client'], {
          queryParams: { id: this.poll.id, name: this.poll.name },
        });
        break;
    }
  }
  labelBoolen(value) {
    let label;
    value ? (label = 'Si') : (label = 'No');
    return label;
  }
}
