import { Injectable } from '@angular/core';
import { Network } from '@ngx-pwa/offline';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ConnectionService {
  private connectionStatusSubject = new BehaviorSubject<boolean>(true);

  constructor(private network: Network) {
    this.network.onlineChanges.subscribe((isOnline) => {
      this.connectionStatusSubject.next(isOnline);
    });
  }

  getConnectionStatus(): Observable<boolean> {
    return this.connectionStatusSubject.asObservable();
  }

  isOnline(): boolean {
    return this.connectionStatusSubject.value;
  }
}
