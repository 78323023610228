<div class="content">
  <app-header
    [title]="this.poll.name"
    [img]="this.sectionImg"
    [actual_section]="poll.sections.length"
    [total_section]="poll.sections.length + 1"
  >
  </app-header
  ><br /><br />
  <app-header-user></app-header-user>

  <div fxLayout="column" class="div-advance">
    <div fxLayout="row" fxLayoutAlign="center center">
      <p>{{ pollMessageFinish.title }}</p>
    </div>
    <div fxLayout="column">
      <div *ngIf="html" [innerHtml]="html | noSanitize : 'html'"></div>
    </div>
  </div>

  <div
    fxLayout="column"
    class="div-advance"
    *ngIf="polls?.length > 0"
    class="content"
  >
    <div fxLayout="row" fxLayoutAlign="center center">
      <p>Listado de encuestas pendientes</p>
    </div>

    <br />
    <div class="content">
      <div class="content-scroll-polls">
        <div fxLayout="column" *ngFor="let poll of polls">
          <mat-card class="card-clients">
            <div fxLayout="row">
              <div fxFlex="10">
                <div
                  fxLayout="column"
                  fxLayoutAlign="center center"
                  class="full-height"
                >
                  <mat-icon class="client-icon">ballot</mat-icon>
                  <!-- <div *ngIf="offlinePolls.includes(poll.id)" class="text-small-bold">Offline</div> -->
                  <div
                    *ngIf="offlinePolls.includes(poll.id)"
                    class="text-small-bold"
                  >
                    <mat-icon>wifi_off</mat-icon>
                  </div>
                  <!-- <div class="text-small-bold">S
            <mat-icon>{{offlinePolls.includes(poll.id) ? 'wifi_off' : 'wifi'}}</mat-icon>
          </div> -->
                </div>
              </div>
              <div fxFlex="55">
                <div fxFlex="column" class="poll-data" style="padding-left: 5px">
                  <div class="poll-data">{{ poll.name }}<br /></div>
                  <div>
                    <span class="text-small-bold"
                      >Expira:
                      {{ poll.expire_date | date : 'dd MMM yyyy' }}</span
                    >
                  </div>
                </div>
              </div>
              <div
                fxFlex="20"
                fxLayoutAlign="center center"
                class="actions-btns"
              >
                <div fxLayout="column" fxLayoutAlign="center center">
                  <!-- <div *ngIf="!offlinePolls.includes(poll.id)" class="text-offline">
              ¿Activar sin conexion?
            </div> -->
                  <!-- <button
              *ngIf="offlinePolls.includes(poll.id)"
              mat-mini-fab
              class="btn-upload"
              [class]="btnSyncControlClass(poll.poll)"
              aria-label="Sincronizar encuesta"
              (click)="pollSyncResponses(poll)"
            >
              <div
                *ngIf="syncPollsCompleted.includes(poll.id)"
                class="badge-sync"
              >
                {{ getCantPollToSync(poll.poll.id) }}
              </div>
              <mat-icon
                *ngIf="!syncPollsIncomplete.includes(poll.id)"
                class="upload-icon"
                matTooltip="Sincronizar Encuesta"
                >{{
                  syncPollsCompleted.includes(poll.poll.id) ? 'sync' : 'sync'
                }}</mat-icon
              >
              <mat-icon
                *ngIf="syncPollsIncomplete.includes(poll.id)"
                class="upload-icon"
                matTooltip="Sincronizar Encuesta"
                >sync_problem</mat-icon
              >
            </button> -->

                  <!-- <div (click)="pollSynchronizationControl(poll)">
            <mat-slide-toggle class="no-click"></mat-slide-toggle>
          </div> -->
                  <!-- <div>
              <mat-slide-toggle class="no-click"></mat-slide-toggle>
            </div> -->
                </div>
              </div>
              <div
                fxFlex="15"
                fxLayoutAlign="center center"
                class="actions-btns"
              >
                <button
                  mat-mini-fab
                  color="black"
                  (click)="initPoll(poll)"
                  aria-label="Seleccionar"
                  class="btn-action ml-4"
                >
                  <mat-icon class="action-icon">navigate_next</mat-icon>
                </button>
              </div>
            </div>
          </mat-card>
        </div>
      </div>
    </div>
  </div>

  <div fxLayout="row" class="btn-footer">
    <div fxFlex="100" fxLayoutAlign="center center">
      <button
        class="btn-next"
        mat-button
        (click)="returnPoll()"
        *ngIf="this.user.id !== this.client.id"
      >
        Ir a clientes
      </button>
      <button
        class="btn-next"
        mat-button
        (click)="returnPoll()"
        *ngIf="this.user.id == this.client.id && this.offlineMode.status"
      >
        Ir a clientes
      </button>
      <button
        class="btn-next"
        mat-button
        (click)="endPoll()"
        *ngIf="this.user.id === this.client.id && !this.offlineMode.status"
      >
        Finalizar
      </button>
    </div>
  </div>
  <ngx-spinner
    bdColor="rgba(51,51,51,0.8)"
    size="medium"
    color="rgb(92, 184, 92)"
    type="ball-spin-fade"
  >
    <p style="font-size: 20px; color: rgb(92, 184, 92)">Cargando...</p>
  </ngx-spinner>

  <ngx-spinner
    name="loading-polls"
    bdColor="rgba(51,51,51,0.8)"
    size="medium"
    color="rgb(92, 184, 92)"
    type="ball-spin-fade"
  >
    <p style="font-size: 20px; color: rgb(92, 184, 92)">
      Cargando encuestas...
    </p>
  </ngx-spinner>
</div>
