<div class="home-container">
  <div class="home-header-container">
    <div class="home-header-logo">
      <span class="logo-name">MAXE APP</span>
      <div class="separador">&nbsp;</div>
      <img class="header-icon" src="assets/images/login/login-maxe-app.png" />
    </div>

    <div class="home-header-user-icon">
      <button mat-button [matMenuTriggerFor]="menu">
        <mat-icon>account_circle</mat-icon>
      </button>

      <mat-menu #menu="matMenu">
        <button (click)="logout()" mat-menu-item>Cerrar sesion</button>
      </mat-menu>
    </div>
  </div>

  <div class="home-boxes-container">
    <div class="common-container">
      <div class="initial-welcome-box">
        <p class="initial-welcome-txt">
          <strong>Bienvenido</strong>
        </p>

        <p>{{ user.name }}</p>
      </div>

      <div class="initial-data-container">
        <div class="initial-data-box">
          <p class="common-box-title">Encuestas activas</p>
          <ng-container
            *ngIf="countPolls >= 0"
          >
            <p class="common-box-text" *ngIf="countPolls >= 0; else spinner">
              {{ countPolls }}
            </p>
          </ng-container>
        </div>

        <div class="initial-data-box">
          <p class="common-box-title">Clientes asignados</p>
          <ng-container
            *ngIf="pollstersCount >= 0"
          >
            <p
              class="common-box-text"
              *ngIf="pollstersCount >= 0; else spinner"
            >
              {{ pollstersCount }}
            </p>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="common-container">
      <div class="common-title-box">
        <p class="common-title-txt">Resumen actividad Mes</p>
      </div>

      <div class="summary-data-container">
        <div class="summary-data-box">
          <p class="common-box-title">Clientes visitados</p>
          <ng-container
            *ngIf="visitedClientsCount >= 0 || !isOffline; else offlineMessage"
          >
            <p
              class="common-box-text"
              *ngIf="visitedClientsCount >= 0; else spinner"
            >
              {{ visitedClientsCount }}
            </p>
          </ng-container>
        </div>

        <div class="summary-data-box">
          <p class="common-box-title">Respuestas</p>
          <ng-container
            *ngIf="
              finishedResponsesCount >= 0 || !isOffline;
              else offlineMessage
            "
          >
            <p
              class="common-box-text"
              *ngIf="finishedResponsesCount >= 0; else spinner"
            >
              {{ finishedResponsesCount }}
            </p>
          </ng-container>
        </div>

        <div class="summary-data-box" *ngIf="showTab">
          <p class="common-box-title">Tareas creadas</p>
          <ng-container
            *ngIf="tasksCreatedCount >= 0 || !isOffline; else offlineMessage"
          >
            <p
              class="common-box-text"
              *ngIf="tasksCreatedCount >= 0; else spinner"
            >
              {{ tasksCreatedCount }}
            </p>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="common-container-box">
      <div class="common-title-box">
        <p class="common-title-txt">Indicadores</p>
        <span>(Ver detalle)</span>
      </div>

      <div class="indicators-data-container">
        <div class="card-container">
          <div class="card-title-box">
            <p class="card-title-txt">Próximamente...</p>
          </div>

          <div class="card-box">
            <p class="card-box-txt">Estamos trabajando en esta sección.</p>
          </div>
        </div>
      </div>
    </div>

    <div class="common-container">
      <div class="common-title-box">
        <p class="common-title-txt">Sincronización</p>
        <mat-icon class="sync-icon">warning</mat-icon>
      </div>

      <div class="sync-data-container">
        <div class="sync-data-box">
          <p class="common-box-title">Encuestas sin sincronizar</p>
          <p class="common-box-text">{{ offlineSyncPollsCount }}</p>
        </div>
      </div>
    </div>
  </div>
  <ng-template #offlineMessage>
    <mat-icon class="mat-icon common-box-text">wifi_off</mat-icon>
  </ng-template>
  <ng-template #spinner>
    <mat-progress-spinner
      class="spinner"
      color="primary"
      mode="indeterminate"
      value="matSliderThumb"
      diameter="20"
    ></mat-progress-spinner>
  </ng-template>
</div>
