import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';

import { PollsService } from 'src/app/polls/services/polls.service';
import { SynchronizationService } from 'src/app/synchronization/services/synchronization.service';
import { OfflineService } from 'src/app/polls/services/offline.service';
import { SyncService } from 'src/app/polls/services/sync.service';
import { Subscription } from 'rxjs';
import { Network } from '@ngx-pwa/offline';

@Component({
  selector: 'app-published-polls',
  templateUrl: './published-polls.component.html',
  styleUrls: ['./published-polls.component.css'],
})
export class PublishedPollsComponent implements OnInit, OnDestroy {
  public polls: any[] = [];
  public countPolls: number = 0;
  public search: string = '';
  public isOfflineMode: boolean = false;
  private syncSubscription: Subscription;
  online$ = this.network.onlineChanges;
  public isOffline: boolean = true;

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event) {
    localStorage.setItem('refreshAttempted', 'true');
  }

  constructor(
    private readonly pollsService: PollsService,
    private readonly spinner: NgxSpinnerService,
    private readonly synchronizationService: SynchronizationService,
    private readonly router: Router,
    private readonly offlineService: OfflineService,
    private syncService: SyncService,
    protected network: Network
  ) {}

  async ngOnInit(): Promise<void> {
    this.syncSubscription = this.syncService.initVerification().subscribe();
    if (localStorage.getItem('refreshAttempted') === 'true') {
      this.router.navigateByUrl('/poll');
      localStorage.setItem('refreshAttempted', 'false');
    }
    this.isOfflineMode = !(localStorage.getItem('statusConnection') === 'true');
    await this.getPolls();
  }

  async getPolls() {
    this.spinner.show('published-polls-spinner');

    //if (!(localStorage.getItem('statusConnection') === 'true')) {
    this.polls = await this.offlineService.getPolls().finally(() => {
      this.spinner.hide('published-polls-spinner');
    });
    this.countPolls = this.polls.length;

    //  return;
    //}

    /* this.pollsService
      .getPollsByUser()
      .pipe(
        finalize(() => {
          this.spinner.hide('published-polls-spinner');
        })
      )
      .subscribe((polls) => {
        this.polls = polls.filter((x) => x.status == 'Publicada');
        this.countPolls = this.polls.length;
      }); */
  }

  checkOffline(poll: any) {
    this.synchronizationService.nextPollPreview({ poll, preview: false });
  }

  ngOnDestroy(): void {
    this.syncSubscription?.unsubscribe();
  }
}
