<!-- <mat-list> -->
<div fxLayout="row">
  <div fxFlex="30" fxLayoutAlign="center center">
    <button
      mat-mini-fab
      color="primary"
      class="example-tab-icon"
      class="mat-elevation-z"
      (click)="closeNavFilter()"
    >
      <mat-icon class="icon_return outline noshadow"> arrow_back</mat-icon>
    </button>
  </div>
  <div fxFlex="30" fxLayoutAlign="center center">
    <mat-icon> filter_list</mat-icon>
    <span class="text_delete_side_nav">FILTROS</span>
  </div>
  <div
    fxFlex="30"
    fxLayoutAlign="center center"
    (click)="deleteSelected(false)"
  >
    <mat-icon> skip_previous</mat-icon>

    <span class="text_delete_side_nav">Borrar todos</span>
  </div>
</div>
<br />

<div
  fxLayoutAlign="center center"
  fxLayout="column"
  *ngIf="this.enterprises.length > 2"
>
  <mat-form-field class="input-full-width mb-3" appearance="outline">
    <mat-label>Información administrativa</mat-label>
    <mat-select
      [(ngModel)]="selectedEnterprise"
      [(value)]="selectedEnterprise"
      (selectionChange)="searchDataSales($event)"
      [compareWith]="compareEnterprise"
      name="enterprisefield"
    >
      <mat-option
        *ngFor="let saleestructure of this.enterprises"
        [value]="saleestructure"
      >
        {{ saleestructure.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div class="row">
  <mat-form-field class="input-full-width-filter" appearance="outline">
    <mat-chip-list #chipList aria-label="Filter selection">
      <mat-chip
        *ngFor="let filter of itemsFilter"
        (removed)="removeItemFilter(filter)"
      >
        {{ filter.name }}
        <button matChipRemove>
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip>
      <input
        class="input-full-width-filter"
        [disabled]="true"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="addOnBlur"
        (matChipInputTokenEnd)="addItemFilter($event)"
      />
    </mat-chip-list>
  </mat-form-field>
</div>
<mat-accordion class="expanded-headers-align" [ngStyle]="">
  <mat-expansion-panel *ngFor="let category of categoriesTarget">
    <mat-expansion-panel-header>
      <mat-panel-description>
        <h5 matLine class="line">
          <span class="text-filter-category">{{ category.label }}</span>
        </h5>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div fxLayout="column" fxLayoutAlign="center center">
      <ng-container *ngIf="category.category === 'Segmento'">
        <mat-form-field class="full-medium-width">
          <mat-chip-list #chipListSeg>
            <input
              placeholder=""
              #segmentoInput
              [formControl]="segmentoCtrl"
              [matAutocomplete]="auto"
              [matChipInputFor]="chipListSeg"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [matChipInputAddOnBlur]="addOnBlur"
            />
          </mat-chip-list>
          <mat-autocomplete
            #auto="matAutocomplete"
            (optionSelected)="selectedSegmento($event, category.category)"
          >
            <mat-option
              *ngFor="let segmento of filteredSegmentos | async"
              [value]="segmento"
            >
              {{ segmento.name }} ({{ segmento.count }})
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-selection-list
          color="primary"
          [(ngModel)]="selectedOptionsSegmentos"
          (selectionChange)="onListSelectionChange($event)"
          [compareWith]="compareFunction"
          #SegmentoTarget
        >
          <mat-list-option
            *ngFor="let category of category.values"
            [value]="category"
            [selected]="category.code === valueCategory[category.source]"
            #optionsSegmentos
            [checkboxPosition]="'before'"
            [id]="category.name"
          >
            <span class="text-font-filter-category"
              >{{ category.name }} ({{ category.count }})</span
            >
          </mat-list-option>
        </mat-selection-list>
      </ng-container>

      <ng-container *ngIf="category.category === 'Relevancia'">
        <mat-form-field class="full-medium-width">
          <mat-chip-list #chipListRel>
            <input
              placeholder=""
              #relevanciaInput
              [formControl]="relevanciaCtrl"
              [matAutocomplete]="autoRel"
              [matChipInputFor]="chipListRel"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [matChipInputAddOnBlur]="addOnBlur"
            />
          </mat-chip-list>
          <mat-autocomplete
            #autoRel="matAutocomplete"
            (optionSelected)="selectedRelevancia($event, category.category)"
          >
            <mat-option
              *ngFor="let relevancia of filteredRelevancias | async"
              [value]="relevancia"
            >
              {{ relevancia.name }} ({{ relevancia.count }})
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-selection-list
          color="primary"
          [(ngModel)]="selectedOptionsRelevancia"
          (selectionChange)="onListSelectionChange($event)"
          #RelevanciaTarget
          [compareWith]="compareFunction"
        >
          <mat-list-option
            *ngFor="let category of category.values"
            [value]="category"
            [selected]="category.code === valueCategory[category.source]"
            #optionsRelevancias
            [checkboxPosition]="'before'"
            [id]="category.name"
          >
            <span>{{ category.name }} ({{ category.count }})</span>
          </mat-list-option>
        </mat-selection-list>
      </ng-container>

      <ng-container *ngIf="category.category === 'Nivel'">
        <mat-form-field class="full-medium-width">
          <mat-chip-list #chipListNivel>
            <input
              placeholder=""
              #nivelInput
              [formControl]="nivelCtrl"
              [matAutocomplete]="autoRel"
              [matChipInputFor]="chipListNivel"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [matChipInputAddOnBlur]="addOnBlur"
            />
          </mat-chip-list>
          <mat-autocomplete
            #autoRel="matAutocomplete"
            (optionSelected)="selectedNivel($event, category.category)"
          >
            <mat-option
              *ngFor="let nivel of filteredNiveles | async"
              [value]="nivel"
            >
              {{ nivel.name }} ({{ nivel.count }})
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-selection-list
          color="primary"
          [(ngModel)]="selectedOptionsNivel"
          (selectionChange)="onListSelectionChange($event)"
          #NivelTarget
          [compareWith]="compareFunction"
        >
          <mat-list-option
            *ngFor="let category of category.values"
            [value]="category"
            [selected]="category.code === valueCategory[category.source]"
            #optionsNiveles
            [checkboxPosition]="'before'"
            [id]="category.name"
          >
            <span>{{ category.name }} ({{ category.count }})</span>
          </mat-list-option>
        </mat-selection-list>
      </ng-container>

      <ng-container *ngIf="category.category === 'Vendedor'">
        <mat-form-field class="full-medium-width">
          <mat-chip-list #chipListVen>
            <input
              placeholder=""
              #vendedorInput
              [formControl]="vendedorCtrl"
              [matAutocomplete]="autoVen"
              [matChipInputFor]="chipListVen"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [matChipInputAddOnBlur]="addOnBlur"
            />
          </mat-chip-list>
          <mat-autocomplete
            #autoVen="matAutocomplete"
            (optionSelected)="selectedVendedor($event, category.category)"
          >
            <mat-option
              *ngFor="let vendedor of filteredVendedores | async"
              [value]="vendedor"
            >
              {{ vendedor.code }}-{{ vendedor.name }} ({{ vendedor.count }})
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-selection-list
          color="primary"
          [(ngModel)]="selectedOptionsVendedor"
          (selectionChange)="onListSelectionChange($event)"
          [compareWith]="compareFunction"
          #VendedorTarget
        >
          <mat-list-option
            *ngFor="let category of category.values"
            [value]="category"
            [selected]="category.code === valueCategory[category.source]"
            #optionsVendedores
            [checkboxPosition]="'before'"
            [id]="category.name"
          >
            <span
              >{{ category.code }}-{{ category.name }} ({{
                category.count
              }})</span
            >
          </mat-list-option>
        </mat-selection-list>
      </ng-container>

      <ng-container *ngIf="category.category === 'Jefe Venta'">
        <mat-form-field class="full-medium-width">
          <mat-chip-list #chipListJefeVta>
            <input
              placeholder=""
              #jefeVtaInput
              [formControl]="jefeVtaCtrl"
              [matAutocomplete]="autoJefeVta"
              [matChipInputFor]="chipListJefeVta"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [matChipInputAddOnBlur]="addOnBlur"
            />
          </mat-chip-list>
          <mat-autocomplete
            #autoJefeVta="matAutocomplete"
            (optionSelected)="selectedJefeVta($event, category.category)"
          >
            <mat-option
              *ngFor="let jefe of filteredJefeVtas | async"
              [value]="jefe"
            >
              {{ jefe.code }}-{{ jefe.name }} ({{ jefe.count }})
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-selection-list
          color="primary"
          [(ngModel)]="selectedOptionsJefeVta"
          (selectionChange)="onListSelectionChange($event)"
          #JefeVtaTarget
          [compareWith]="compareFunction"
        >
          <mat-list-option
            *ngFor="let category of category.values"
            [value]="category"
            [selected]="category.code === valueCategory[category.source]"
            #optionsJefeVta
            [checkboxPosition]="'before'"
            [id]="category.name"
          >
            <span
              >{{ category.code }}-{{ category.name }} ({{
                category.count
              }})</span
            >
          </mat-list-option>
        </mat-selection-list>
      </ng-container>

      <ng-container *ngIf="category.category === 'Subgerente'">
        <mat-form-field class="full-medium-width">
          <mat-chip-list #chipListSubgerente>
            <input
              placeholder=""
              #SubGerenteInput
              [formControl]="SubGerenteCtrl"
              [matAutocomplete]="autoSubGerente"
              [matChipInputFor]="chipListSubgerente"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [matChipInputAddOnBlur]="addOnBlur"
            />
          </mat-chip-list>
          <mat-autocomplete
            #autoSubGerente="matAutocomplete"
            (optionSelected)="selectedSubGerente($event, category.category)"
          >
            <mat-option
              *ngFor="let subgerente of filteredSubGerente | async"
              [value]="subgerente"
            >
              {{ subgerente.code }}-{{ subgerente.name }} ({{
                subgerente.count
              }})
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-selection-list
          color="primary"
          [(ngModel)]="selectedOptionsSubGerente"
          (selectionChange)="onListSelectionChange($event)"
          #SubGerenteTarget
          [compareWith]="compareFunction"
        >
          <mat-list-option
            *ngFor="let category of category.values"
            [value]="category"
            [selected]="category.code === valueCategory[category.source]"
            #optionsSubGerente
            [checkboxPosition]="'before'"
            [id]="category.name"
          >
            <span
              >{{ category.code }}-{{ category.name }} ({{
                category.count
              }})</span
            >
          </mat-list-option>
        </mat-selection-list>
      </ng-container>

      <ng-container *ngIf="category.category === 'Gerente'">
        <mat-form-field class="full-medium-width">
          <mat-chip-list #chipListGerente>
            <input
              placeholder=""
              #GerenteInput
              [formControl]="GerenteCtrl"
              [matAutocomplete]="autoGerente"
              [matChipInputFor]="chipListGerente"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [matChipInputAddOnBlur]="addOnBlur"
            />
          </mat-chip-list>
          <mat-autocomplete
            #autoGerente="matAutocomplete"
            (optionSelected)="selectedGerente($event, category.category)"
          >
            <mat-option
              *ngFor="let gerente of filteredGerente | async"
              [value]="gerente"
            >
              {{ gerente.code }}-{{ gerente.name }} ({{ gerente.count }})
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-selection-list
          color="primary"
          [(ngModel)]="selectedOptionsGerente"
          (selectionChange)="onListSelectionChange($event)"
          #GerenteTarget
          [compareWith]="compareFunction"
        >
          <mat-list-option
            *ngFor="let category of category.values"
            [value]="category"
            [selected]="category.code === valueCategory[category.source]"
            #optionsGerente
            [checkboxPosition]="'before'"
            [id]="category.name"
          >
            <span
              >{{ category.code }}-{{ category.name }} ({{
                category.count
              }})</span
            >
          </mat-list-option>
        </mat-selection-list>
      </ng-container>

      <ng-container *ngIf="category.category === 'Oficina comercial'">
        <mat-form-field class="full-medium-width">
          <mat-chip-list #chipListOficCom>
            <input
              placeholder=""
              #OficComInput
              [formControl]="OficComCtrl"
              [matAutocomplete]="autoOficCom"
              [matChipInputFor]="chipListOficCom"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [matChipInputAddOnBlur]="addOnBlur"
            />
          </mat-chip-list>
          <mat-autocomplete
            #autoOficCom="matAutocomplete"
            (optionSelected)="selectedOficCom($event, category.category)"
          >
            <mat-option
              *ngFor="let oficina of filteredOficCom | async"
              [value]="oficina"
            >
              {{ oficina.code }}-{{ oficina.name }} ({{ oficina.count }})
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-selection-list
          color="primary"
          [(ngModel)]="selectedOptionsOficCom"
          (selectionChange)="onListSelectionChange($event)"
          #OficComTarget
          [compareWith]="compareFunction"
        >
          <mat-list-option
            *ngFor="let category of category.values"
            [value]="category"
            [selected]="category.code === valueCategory[category.source]"
            #optionsOficCom
            [checkboxPosition]="'before'"
            [id]="category.name"
          >
            <span
              >{{ category.code }}-{{ category.name }} ({{
                category.count
              }})</span
            >
          </mat-list-option>
        </mat-selection-list>
      </ng-container>

      <ng-container *ngIf="category.category === 'Distrito'">
        <mat-form-field class="full-medium-width">
          <mat-chip-list #chipListDistrito>
            <input
              placeholder=""
              #DistritoInput
              [formControl]="DistritoCtrl"
              [matAutocomplete]="autoDistrito"
              [matChipInputFor]="chipListDistrito"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [matChipInputAddOnBlur]="addOnBlur"
            />
          </mat-chip-list>
          <mat-autocomplete
            #autoDistrito="matAutocomplete"
            (optionSelected)="selectedDistrito($event, category.category)"
          >
            <mat-option
              *ngFor="let distrito of filteredDistrito | async"
              [value]="distrito"
            >
              {{ distrito.code }}-{{ distrito.name }} ({{ distrito.count }})
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-selection-list
          color="primary"
          [(ngModel)]="selectedOptionsDistrito"
          (selectionChange)="onListSelectionChange($event)"
          #DistritoTarget
          [compareWith]="compareFunction"
        >
          <mat-list-option
            *ngFor="let category of category.values"
            [value]="category"
            [selected]="category.code === valueCategory[category.source]"
            #optionsDistrito
            [checkboxPosition]="'before'"
            [id]="category.name"
          >
            <span
              >{{ category.code }}-{{ category.name }} ({{
                category.count
              }})</span
            >
          </mat-list-option>
        </mat-selection-list>
      </ng-container>

      <ng-container *ngIf="category.category === 'Zona'">
        <mat-form-field class="full-medium-width">
          <mat-chip-list #chipListZona>
            <input
              placeholder=""
              #ZonaInput
              [formControl]="ZonaCtrl"
              [matAutocomplete]="autoZona"
              [matChipInputFor]="chipListZona"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [matChipInputAddOnBlur]="addOnBlur"
            />
          </mat-chip-list>
          <mat-autocomplete
            #autoZona="matAutocomplete"
            (optionSelected)="selectedZona($event, category.category)"
          >
            <mat-option
              *ngFor="let zona of filteredZona | async"
              [value]="zona"
            >
              {{ zona.code }}-{{ zona.name }} ({{ zona.count }})
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-selection-list
          color="primary"
          [(ngModel)]="selectedOptionsZona"
          (selectionChange)="onListSelectionChange($event)"
          #ZonaTarget
          [compareWith]="compareFunction"
        >
          <mat-list-option
            *ngFor="let category of category.values"
            [value]="category"
            [selected]="category.code === valueCategory[category.source]"
            #optionsZona
            [checkboxPosition]="'before'"
            [id]="category.name"
          >
            <span
              >{{ category.code }}-{{ category.name }} ({{
                category.count
              }})</span
            >
          </mat-list-option>
        </mat-selection-list>
      </ng-container>
    </div>
  </mat-expansion-panel>
</mat-accordion>
<mat-card>
  <mat-card-content>
    <section class="example-section">
      <mat-checkbox class="mat-checkbox-input" [(ngModel)]="keepFilter"
        ><b>Mantener filtros en el dispositivo</b></mat-checkbox
      >
    </section>
  </mat-card-content>
</mat-card>
<!-- </mat-list> -->
<br />
<ngx-spinner
  name="loading"
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="rgb(92, 184, 92)"
  type="ball-spin-fade"
>
  <p style="font-size: 20px; color: rgb(92, 184, 92)">Cargando categorias...</p>
</ngx-spinner>

<button
  mat-raised-button
  class="btn-next fixed-button menu-button center"
  (click)="applyFilter()"
>
  <!-- <span *ngIf="!isOfflineMode">Aplicar ({{ this.categoriesClientCount }})</span> -->
  <span>Aplicar</span>
</button>
