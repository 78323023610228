<div fxLayout="column">
  <div class="header-radius header-shadow"></div>

  <div class="title" fxLayoutAlign="center center">{{ title }}</div>
  <div class="sub-title" fxLayoutAlign="center center" *ngIf="sub_title">
    {{ sub_title }}
  </div>
  <div
    class="sub-title margin"
    fxLayoutAlign="center center"
    *ngIf="actual_section"
  >
    <span class="small-sub-title">Sección&nbsp;</span>
    <b>{{ actual_section }}</b>
    <span class="small-sub-title">&nbsp;de&nbsp;</span>
    <b>{{ total_section }}</b>
  </div>
</div>
<br /><br /><br />
