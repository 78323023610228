import { NgxSpinnerService } from 'ngx-spinner';
import { Component, OnDestroy, OnInit } from '@angular/core';

import { TaskByClient } from '../../interfaces';
import { TasksService } from '../../services/tasks.service';
import { SyncService } from 'src/app/polls/services/sync.service';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ConnectionService } from 'src/app/polls/services/connection.service';

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.css'],
})
export class TasksComponent implements OnInit, OnDestroy {
  public createdTask: TaskByClient[];
  public assignedTasks: TaskByClient[];
  public createdPendingCount: number;
  public createdCompletedCount: number;
  public assignedPendingCount: number;
  public assignedCompletedCount: number;
  private syncSubscription: Subscription;
  private assignedSubscription: Subscription;
  private createdSubscription: Subscription;

  public offline: boolean = false;
  public isOffline: boolean = true;

  constructor(
    private readonly taskService: TasksService,
    private spinner: NgxSpinnerService,
    private syncService: SyncService,
    private router: Router,
    private connectionService: ConnectionService
  ) {}

  ngOnInit(): void {
    this.isOffline = !(localStorage.getItem('statusConnection') === 'true');
    this.connectionService.getConnectionStatus().subscribe((status) => {
      localStorage.setItem('statusConnection', `${status}`);
      this.isOffline = !status;
      if (status) {
        this.loadInitialData();
      }
    });
    this.offline = localStorage.getItem('isOfflineMode') === 'yes';
  }
  loadInitialData() {
    this.syncSubscription = this.syncService.initVerification().subscribe();
    // this.spinner.show('loading-spinner');
    this.assignedSubscription = this.taskService
      .fetchAssignedTasks()
      .subscribe((countAssigned) => {
        this.assignedPendingCount = countAssigned[0];
        this.assignedCompletedCount = countAssigned[1];
      });

    this.createdSubscription = this.taskService
      .fetchCreatedTasks()
      .pipe(finalize(() => this.spinner.hide('loading-spinner')))
      .subscribe((countCreated) => {
        this.createdPendingCount = countCreated[0];
        this.createdCompletedCount = countCreated[1];
      });
  }

  ngOnDestroy(): void {
    this.syncSubscription?.unsubscribe();
    this.assignedSubscription?.unsubscribe();
    this.createdSubscription?.unsubscribe();
  }

  navigateToCreatedTasks(): void {
    this.taskService.setTabIndex(0);
    this.router.navigate(['/tasks/created']);
  }

  navigateToAssignedTasks(): void {
    this.taskService.setTabIndex(0);
    this.router.navigate(['/tasks/assigned']);
  }
}
