<mat-sidenav-container class="global-bg-gradient container-drawer">
  <mat-sidenav #sidenavSelectRoute class="content-mat-drawer">
    <app-select-route
      (closeNavSelectRouteEvent)="closeNav($event)"
      (setListOptionsVendedor)="setListOptionsVendedor($event)"
      (progressChange)="handleProgressChange($event)"
      (progressTextChange)="progressTextChange($event)"
      (progressSpinnerChange)="progressSpinnerChange($event)"
    ></app-select-route>
  </mat-sidenav>
  <mat-sidenav-content>
    <app-shared-header
      [showTitle]="true"
      title="Sincronización"
      [showBadge]="false"
    ></app-shared-header>
    <div class="offline-container">
      <div class="sync-toolbar-box">
        <div class="tasks-section-btn-box">
          <div class="btn-title-box">
            <p class="btn-title">Configuración de sincronización</p>
          </div>

          <div class="btn-icon-box">
            <mat-icon (click)="configSync()">navigate_next</mat-icon>
          </div>
        </div>
      </div>

      <div class="sync-active-box">
        <div
          *ngIf="synchronizationInProgress || stopInProgress; else pollsList"
        ></div>

        <ng-template #pollsList>
          <div
            class="sync-info-box"
            *ngIf="!(clientsWithPolls$ | async)?.length"
          >
            <p class="sync-info-txt">Sin encuestas por sincronizar</p>
          </div>

          <div
            class="content-clients"
            *ngIf="(clientsWithPolls$ | async)?.length"
          >
            <section class="section-box" infiniteScroll>
              <div
                style="margin-bottom: 3px"
                fxLayout="column"
                *ngFor="let client of clientsWithPolls$ | async; let i = index"
              >
                <mat-accordion class="accordion-headers-align" multi>
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title class="panelTitle">
                        <div class="sync-client-badge">
                          {{ client.polls.length }}
                        </div>

                        <div class="sync-client-info">
                          <span class="text-limited">{{
                            client.descr_cliente
                          }}</span>
                          <br />
                          <span class="text-limited">{{
                            client.direccion
                          }}</span>
                          <br />
                          <span class="datePoll"
                            >Código: {{ client.cliente_id }}</span
                          >
                        </div>
                      </mat-panel-title>
                    </mat-expansion-panel-header>

                    <mat-card
                      *ngFor="let poll of client.polls"
                      class="sync-poll-card"
                      style="margin-bottom: 5px"
                    >
                      <div fxLayout="row">
                        <div class="sync-poll-icon">
                          <mat-icon class="client-icon">ballot</mat-icon>
                        </div>

                        <div fxFlex="100">
                          <div class="sync-poll-box" fxFlex="column">
                            <div class="sync-poll-name">{{ poll.name }}</div>

                            <div>
                              Iniciada el:
                              {{ poll.date_init | date : 'dd MMM yyyy' }}
                            </div>

                            <div>
                              Finalizada el:
                              {{ poll.date_finish | date : 'dd MMM yyyy' }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </mat-card>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>
            </section>

            <!-- <div class="sync-btn-box">
              <div class="sync-btn-icon" (click)="synchronize()">
                <mat-icon>sync</mat-icon>
              </div>

              <p class="sync-btn-txt">Sincronizar</p>
            </div> -->
          </div>
        </ng-template>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

<ngx-spinner
  name="upload-spinner"
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="rgb(92, 184, 92)"
  type="line-scale"
>
  <p style="font-size: 16px; color: rgb(255, 255, 255); text-align: center">
    {{ progressSteps || stopProgressSteps }}
  </p>
</ngx-spinner>

<ngx-spinner
  name="synchronizing"
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="rgb(92, 184, 92)"
  type="ball-clip-rotate-pulse"
>
  <div class="progress-upload">
    <div style="margin-top: 40px; margin-bottom: 12px">Sincronizando</div>
    <mat-progress-bar
      mode="determinate"
      [value]="progressValue"
      class="sync-progress-bar"
    ></mat-progress-bar>
    <div style="margin-top: 16px">
      {{ progressText }}
      <span>{{ progressValue }}%</span>
    </div>
  </div>
</ngx-spinner>
