import * as jwt_decode from 'jwt-decode';
import { Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';

import { AuthService } from 'src/app/auth/auth.service';
import { PollsService } from 'src/app/polls/services/polls.service';
import { OfflineService } from 'src/app/polls/services/offline.service';
import { PollResponseService } from 'src/app/polls/services/poll-response.service';
import { PollstersService } from 'src/app/polls/services/pollsters.service';
import { TasksService } from 'src/app/tasks/services/tasks.service';
import { Subscription } from 'rxjs';
import { SyncService } from 'src/app/polls/services/sync.service';
import { ConnectionService } from 'src/app/polls/services/connection.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit, OnDestroy {
  public user: any;
  public pollstersCount: number;
  public finishedResponsesCount: number;
  public pendingResponsesCount: number;
  public visitedClientsCount: number;
  public tasksCreatedCount: number;
  public offline: boolean = false;
  public offlineSyncPollsCount: number = 0;

  private syncSubscription: Subscription;

  // Temporal
  public countPolls: number;

  showTab: boolean = false;
  public isOffline: boolean = true;

  constructor(
    private readonly router: Router,
    private readonly pollstersService: PollstersService,
    private readonly pollResponseService: PollResponseService,
    private readonly authService: AuthService,
    private readonly tasksService: TasksService,
    // Temporal
    private readonly pollsService: PollsService,
    private readonly offlineService: OfflineService,
    private syncService: SyncService,
    private connectionService: ConnectionService
  ) {
    this.user = jwt_decode(localStorage.getItem('token')).user;
  }

  ngOnInit(): void {
    this.getPollstersCount();
    this.fetchPolls();
    this.isOffline = !(localStorage.getItem('statusConnection') === 'true');
    this.connectionService.getConnectionStatus().subscribe((status) => {
      localStorage.setItem('statusConnection', `${status}`);
      this.isOffline = !status;
      if (status) this.loadInitialData();
    });

    this.syncSubscription = this.syncService.initVerification().subscribe();
    this.offline = localStorage.getItem('isOfflineMode') === 'yes';
  }

  loadInitialData() {
    this.getPollResponses();
    this.loadSyncPollsCount();
    this.getCreatedTasks();
  }

  verifyEnterprisesToShowContent() {
    const enterprises = JSON.parse(localStorage.getItem('enterprises') || '[]');
    const idsToCheck = [99, 98, 92];
    if (
      idsToCheck?.every(
        (id) => !enterprises.some((enterprise: any) => enterprise.id === id)
      )
    ) {
      //this.showTab = true;
      this.showTab = false;
    }
  }

  getPollstersCount() {
    this.offlineService.getOfflineHeader(1, this.user.id).then((res) => {
      this.pollstersCount = res.number_clients;
    });
  }

  async getPollResponses() {
    this.pollResponseService.getPollResponse().subscribe((res) => {
      this.finishedResponsesCount = res.finished.length;
      this.pendingResponsesCount = res.started.length;
      this.visitedClientsCount = res.clientsIds.length;
    });
  }

  async fetchPolls() {
    const polls = await this.offlineService.getPolls();
    this.countPolls = polls.length;
  }

  logout(): void {
    let filterPollAllData: any = localStorage.getItem('filterPollAllData');

    localStorage.clear();
    localStorage.clear();

    sessionStorage.removeItem('sentryReplaySession');

    if (filterPollAllData !== null) {
      if (JSON.parse(filterPollAllData).length) {
        localStorage.setItem('filterPollAllData', filterPollAllData);
      }
    }

    this.authService.SignOut().then(() => this.router.navigateByUrl('/login'));
  }

  async loadSyncPollsCount() {
    const syncPolls = await this.offlineService.getSyncPolls();

    this.offlineSyncPollsCount =
      syncPolls.filter(
        (sp) =>
          sp.status === 'unsynchronized-edit' ||
          sp.status === 'unsynchronized' ||
          sp.status === 'sync-responses-completed' ||
          sp.status === 'sync-close-response' ||
          sp.status === 'sync-completed'
      ).length || 0;
  }

  getCreatedTasks() {
    this.tasksService.getCreatedTasks(this.user.id).subscribe((res) => {
      this.tasksCreatedCount = res.data.length;
    });
  }

  ngOnDestroy(): void {
    this.syncSubscription?.unsubscribe();
  }
}
