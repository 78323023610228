<app-shared-header
  title="Tareas"
  [showTitle]="true"
  [showBadge]="false"
></app-shared-header>

<div class="tasks-container">
  <div class="tasks-section-container">
    <div class="tasks-section-title-box">
      <p class="section-title">Tareas Asignadas a mi</p>
    </div>

    <div class="tasks-section-counts-box">
      <div class="count-box">
        <div class="count-title-box">
          <p class="count-title">Pendientes</p>
        </div>

        <div class="count-num-box">
          <ng-container
            *ngIf="assignedPendingCount >= 0 || !isOffline; else offlineMessage"
          >
            <p
              class="count-num warning-color"
              *ngIf="assignedPendingCount >= 0; else spinner"
            >
              {{ assignedPendingCount }}
            </p>
          </ng-container>
        </div>
      </div>

      <div class="count-box">
        <div class="count-title-box">
          <p class="count-title">Completadas</p>
        </div>

        <div class="count-num-box">
          <ng-container
            *ngIf="
              assignedCompletedCount >= 0 || !isOffline;
              else offlineMessage
            "
          >
            <p
              class="count-num"
              *ngIf="assignedCompletedCount >= 0; else spinner"
            >
              {{ assignedCompletedCount }}
            </p>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="tasks-section-btn-box" (click)="navigateToAssignedTasks()">
      <div class="btn-title-box">
        <p class="btn-title">Detalle de las tareas asignadas</p>
      </div>

      <div class="btn-icon-box">
        <mat-icon>navigate_next</mat-icon>
      </div>
    </div>
  </div>

  <span class="separation"></span>

  <div class="tasks-section-container">
    <div class="tasks-section-title-box">
      <p class="section-title">Tareas Creadas por mi</p>
    </div>

    <div class="tasks-section-counts-box">
      <div class="count-box">
        <div class="count-title-box">
          <p class="count-title">Pendientes</p>
        </div>

        <div class="count-num-box">
          <ng-container
            *ngIf="createdPendingCount >= 0 || !isOffline; else offlineMessage"
          >
            <p
              class="count-num warning-color"
              *ngIf="createdPendingCount >= 0; else spinner"
            >
              {{ createdPendingCount }}
            </p>
          </ng-container>
        </div>
      </div>

      <div class="count-box">
        <div class="count-title-box">
          <p class="count-title">Completadas</p>
        </div>
        <div class="count-num-box">
          <ng-container
            *ngIf="
              createdCompletedCount >= 0 || !isOffline;
              else offlineMessage
            "
          >
            <p
              class="count-num"
              *ngIf="createdCompletedCount >= 0; else spinner"
            >
              {{ createdCompletedCount }}
            </p>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="tasks-section-btn-box" (click)="navigateToCreatedTasks()">
      <div class="btn-title-box">
        <p class="btn-title">Detalle de las tareas creadas</p>
      </div>

      <div class="btn-icon-box">
        <mat-icon>navigate_next</mat-icon>
      </div>
    </div>
  </div>

  <span class="separation"></span>

  <div class="tasks-section-container">
    <div class="tasks-section-title-box">
      <p class="section-title">Crear Tareas</p>
    </div>

    <div class="tasks-section-btn-box">
      <div class="create-task-icon">
        <mat-icon>task_alt</mat-icon>
      </div>

      <div class="create-task-title-box">
        <p class="btn-title">Buscar cliente para asignar una tarea</p>
      </div>

      <div class="btn-icon-box" routerLink="/poll">
        <mat-icon>navigate_next</mat-icon>
      </div>
    </div>
  </div>
</div>

<ngx-spinner
  name="loading-spinner"
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="rgb(92, 184, 92)"
  type="ball-spin-fade"
>
  <p
    style="
      font-size: 16px;
      color: rgb(255, 255, 255);
      padding-top: 30px;
      text-align: center;
    "
  >
    Cargando informacion...
  </p>
</ngx-spinner>
<ng-template #offlineMessage>
  <mat-icon class="mat-icon common-box-text">wifi_off</mat-icon>
</ng-template>
<ng-template #spinner>
  <mat-progress-spinner
    class="spinner"
    color="primary"
    mode="indeterminate"
    value="matSliderThumb"
    diameter="20"
  ></mat-progress-spinner>
</ng-template>
