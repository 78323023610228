<app-shared-header
  title="Nueva Tarea"
  [showTitle]="true"
  [showBadge]="false"
></app-shared-header>

<div class="tasks-create-container">
  <div class="client-box">
    <div class="client-name-box">
      <p class="name-title">Cliente</p>
    </div>

    <div class="client-info-box">
      <div class="client-icon">
        <mat-icon>perm_contact_calendar</mat-icon>
      </div>

      <div class="client-info">
        <p class="client-txt">{{ client.descr_cliente }}</p>
        <p class="client-txt">{{ client.direccion }}</p>
        <p class="client-txt">Código: {{ client.cliente_id }}</p>
      </div>
    </div>
  </div>

  <div class="templates-box">
    <div class="not-templates" *ngIf="!templates.length; else taskCreation">
      <p class="not-templates-msg">
        El cliente seleccionado no tiene tareas para crear.
      </p>
    </div>

    <ng-template #taskCreation>
      <div *ngIf="!selectedTemplate; else limit_date">
        <div class="templates-title-box">
          <p class="templates-title">¿Que tarea quieres agregar?</p>
        </div>

        <div class="templates-list-box">
          <div *ngFor="let template of templates">
            <div
              class="template-box"
              [ngClass]="{
                'template-selected': selectedTemplate?.id === template.id
              }"
              (click)="handleSelectTemplate(template)"
            >
              <p class="template-title">{{ template.title }}</p>
            </div>
          </div>
        </div>
      </div>

      <ng-template #limit_date>
        <div class="template-question-box" *ngIf="showDate; else questions">
          <div>
            <div class="template-question">
              <app-template-date
                (answer)="handleDateAnswer($event)"
                [answerMaded]="limitDate"
              ></app-template-date>
            </div>
          </div>
        </div>
      </ng-template>

      <ng-template #questions>
        <div class="template-question-box">
          <div [ngSwitch]="currentQuestion?.type_id">
            <div class="template-question" *ngSwitchCase="'3'">
              <app-template-text
                (handleResponse)="handleTextAnswer($event)"
                [question]="currentQuestion"
              ></app-template-text>
            </div>

            <div class="template-question" *ngSwitchCase="'4'">
              <app-template-photo
                (answer)="handlePhotoAnswer($event)"
                [question]="currentQuestion"
                [client]="client"
                [user]="user"
              ></app-template-photo>
            </div>
          </div>
        </div>
      </ng-template>
    </ng-template>
  </div>

  <div fxLayout="row" class="btn-footer">
    <div fxFlex="50" fxLayoutAlign="start center">
      <button class="back-button" mat-button (click)="handleBack()">
        Volver
      </button>
    </div>

    <div fxFlex="50" fxLayoutAlign="end center">
      <button
        *ngIf="templates.length"
        class="next-button"
        [ngClass]="{
          'next-button-active': !allowMoveQuestion,
          'next-button-disabled': allowMoveQuestion
        }"
        mat-button
        [disabled]="!allowMoveQuestion"
        (click)="handleNext()"
      >
        <div
          *ngIf="
            !selectedTemplate ||
              questionPosition < selectedTemplate?.questions?.length - 1 ||
              (showDate && selectedTemplate?.questions?.length !== 0);
            else finish
          "
        >
          Siguiente
        </div>

        <ng-template #finish>
          <div>Finalizar</div>
        </ng-template>
        <!-- <mat-icon>navigate_next</mat-icon> -->
      </button>
    </div>
  </div>

  <ngx-spinner
    name="find-tasks-spinner"
    bdColor="rgba(51,51,51,0.8)"
    size="medium"
    color="rgb(92, 184, 92)"
    type="ball-spin-fade"
  >
    <p style="font-size: 14px; color: rgb(255, 255, 255); width: 190px">
      Buscando tareas...
    </p>
  </ngx-spinner>

  <ngx-spinner
    name="upload-response-spinner"
    bdColor="rgba(51,51,51,0.8)"
    size="medium"
    color="rgb(92, 184, 92)"
    type="ball-spin-fade"
  >
    <p style="font-size: 14px; color: rgb(255, 255, 255)">Creando tarea...</p>
  </ngx-spinner>
</div>
